.Summary {
  float: right;
  font-size: 12px;
  color: rgba(255, 255, 255, .5);
  padding-right: 15px;
  font-weight: 600;
}
.SideNavbar {
  background: #1D2B3D;
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  z-index: 1000;
  /* height: auto; */
  height: 100vh;
  /* padding: 0 12px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  transform: translateX(0px);
  transition: transform .3s ease-in;
}
.SideNavbar.close {
  transform: translateX(-330px);
}

.SideNavbar::-webkit-scrollbar {
  width: 8px;
}

.SideNavbar::-webkit-scrollbar-track {
  background:#1D2B3D;
  border-radius: 4px;
  /* opacity: 0.5; */
}
.SideNavbar::-webkit-scrollbar-thumb {
  background: #4a5b70;
  border-radius: 4px;
  opacity: 1;
}
/* ::-webkit-scrollbar-thumb:hover {
  background:yellow;
} */

.SideNavbar .Header {
  display: flex;
  align-items: center;
  margin: 0 4px 0 12px;
  padding: 24px 19px;
}
/* .SideNavbar .Header .IconMenu svg{
  width: 100%;
} */
.SideNavbar .Header .IconMenu {
  display: flex;
  color: #FFFFFF;
  opacity: 0.5;
  width: 18px;
  height: 12px;
  margin-right: 15px;
  cursor: pointer;
}
.SideNavbar .Header .logo {
  width: 105px;
  height: 18px;
  margin-right: 8px;
  cursor: pointer;
}
.SideNavbar .Header .section {
  color: rgba(255, 255, 255, .5);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}
.SideNavbar .SearchField {
  margin: 0 4px 0 12px;
  display: flex;
  height: 52px;
  padding: 0px 16px 5px 16px;
}
.SideNavbar .Items {
  flex-grow: 1;
  margin: 0 4px 0 12px;
}

.SideNavbar .NotFound {
  /* margin: 0px 12px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  color: #fff;
}

.SideNavbar .NotFound div {
  width: 192px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  opacity: .7;
}

.Box {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  padding: 20px;
}
.Box .IconMenu{
  color: #808080;
  cursor: pointer;
  margin-right: 10px;
}
.Box .Logo{
  cursor: pointer;
}

/* @media screen and (max-width: 1124px) {
  .Box {
    top: 24px;
    left: 19px;
  }
} */