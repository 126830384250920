.CollapsibleSubsection .toggle {
  border: unset;
  cursor: pointer;
  background: #1D2B3D;
  color: #fff;
  opacity: 50%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 296px;
  min-height: 40px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  border-radius: 4px;
}

.CollapsibleSubsection .toggle:hover {
  background: #fff;
  color: #1D2B3D;
}

.CollapsibleSubsection .toggle span {
  /* margin: 10px 0 10px 12px; */
  line-height: 20px;
  display: inline-block;
}

.CollapsibleSubsection .content {
  color: #fff;
  /* height: 0px; */
  overflow: hidden;
  transition: all ease 0.5s;
}