.tree {
  height: 100%;
}

/* .react-flow__node:not(hover) {
  opacity: 0.7;
} */
/* .react-flow__handle {
  opacity: 0;
}  */
.tree {
  text-align: center;
}

.react-flow__node-section.selected,
.react-flow__node-subsection.selected,
.react-flow__node-physics.selected,
.react-flow__node-lesson.selected {
  border: 3px solid aqua;
}

.react-flow__node-section:hover,
.react-flow__node-subsection:hover,
.react-flow__node-physics:hover,
.react-flow__node-lesson:hover {
  background: #EBF4FF;
}
.react-flow__node-section, .react-flow__node-subsection, .react-flow__node-physics, .react-flow__node-lesson {
  background: #fff;
  border: 2px solid #254AA5;
  border-radius: 4px;
  width: 240px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items:center;
  font-weight: 600;
  font-size: 12px;
  color: #1D2B3D;
  padding: 0 16px;
  cursor: pointer;
}
/* .react-flow__node-subsection {
  min-height: 116px;
  padding: 12px 16px 16px;
  align-items: start;
  justify-content: start;
  flex-direction: column;
}
.react-flow__node-subsection .subsection {
  display: flex;
  flex-direction: column;
  align-items:stretch;
}
.react-flow__node-subsection .subsection .desciption {
  margin-bottom: 4px;
  overflow: hidden;
  /* white-space: nowrap; */
 /* text-overflow: ellipsis;
  text-align: justify;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  display: inline-block;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-all;
}
.react-flow__node-subsection .subsection .anchor {
  display: flex;
  white-space:wrap;
}
.react-flow__node-subsection .subsection .anchor span{
  color: #254AA5;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}*/
.react-flow__node-subsection .subsection .wrapper {
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 208px;
}
.react-flow__node-subsection .subsection .wrapper .title {
  color:#1E2536;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.react-flow__node-subsection .subsection .wrapper .flag {
  margin-left: 2px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #808080;
}
/* .react-flow__node-lesson:hover {
  color: #fff;
  background-color: rgb(0, 127, 0);
  cursor: pointer;
} */
/* .flag {
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100%;
  background-color: red;
  color: white;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:x-small;
} */

.react-flow__handle {
  border: none;
  background:none;
}


.react-flow__controls-button {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  /* border: 1 solid #ccc; */
}
.react-flow__controls-button:hover {
  background: #F5F5F5;
}
.react-flow__controls-button > svg {
  fill: #808080;
  stroke: 2px;
  width: 50%;
}
.control-panel {
  bottom: 50%;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.react-flow__panel.bottom.control-panel {
  bottom: 50%;
  transform: translateY(50%);
}
.states-controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 4;
  display: flex;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 96px;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 1px rgba(0, 0, 0, 0.05); */
}
.button-wrap,
.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 4px;
}
.button-wrap:first-child{
  border-right: 1px solid #ccc;
}

.button-wrap button,
.control button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  height: 100%;
  color: #808080;
  background: #FFFFFF;
}
.control button {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.button-wrap button:hover,
.control button:hover {
  background: #F5F5F5;
}
.button-wrap button > div {
  width: 9px;
  height: 17px;
}
.react-flow__attribution {
  visibility: hidden;
}
.panel-controls {
  position: absolute;
  bottom: 50%;
  /* transform: translateX(-48px) translateY(50%) rotate(90deg); */
  left: 20px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 48px;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 1px rgba(0, 0, 0, 0.05); */
}

.save_controls__minus {
  width: 14px;
  height: 2px;
  display: flex;
  justify-content: center;
  align-items:center;
  /* transform: translateY(-25%); */
}

.control button > div {
  width: 14px;
  height: 14px;
}

.select-fields {
  position: absolute;
  top: 20px;
  left: 80px;
  z-index: 4;
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
}
.btn-task {
  height: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 10px 16px;
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.15), 0px 3px 16px 1.5px rgba(0, 0, 0, 0.08);
}
.btn-task:hover {
  background: #F5F5F5;
  cursor: pointer;
}

@media screen and (max-width: 426px) {
  .select-fields {
    top: 60px;
    left: 20px;
  }
}