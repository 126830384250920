.SkeletonAsideWindow {
  background: #FFFFFF;
  min-height: 100vh;
  max-width: 400px;
  box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  transition: all ease .4s;
  position: fixed;
  top: 0;
  right: 0;
}

.SkeletonAsideWindow .Header {
  display: flex;
  justify-content: space-between;
  padding: 33px 33px 28px 20px;
}
.SkeletonAsideWindow .Header h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
}
.SkeletonAsideWindow .Header .close {
  color: #808080;
  display: flex;
  justify-content: center;
  align-items:center;
  align-self: start;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.SkeletonAsideWindow .Header .close:hover {
  color:rgba(255, 255, 255, 0.5);
  background: #1D2B3D;
}
.SkeletonAsideWindow .video {
  width: 400px;
  height: 300px;
  background: #808080;
  opacity: 0.1;
}
.SkeletonAsideWindow .TextBlock {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  height: 100%;
}
.SkeletonAsideWindow .TextBlock .TagsList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  justify-content: space-between;
}
.SkeletonAsideWindow .TextBlock .TagsList > div {
  background: #808080;
  opacity: 0.1;
  border-radius: 4px;
  height: 24px;
  width: 113px;
}
.SkeletonAsideWindow .TextBlock .description {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.SkeletonAsideWindow .TextBlock .description > div {
  background: #808080;
  opacity: 0.1;
  border-radius: 4px;
  height: 18px;
  width: 100%;
}
.SkeletonAsideWindow .TextBlock .description > div:nth-child(2) {
  width: 93%;
}
.SkeletonAsideWindow .TextBlock .description > div:nth-child(3) {
  width: 86%;
}