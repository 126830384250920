.ScrollUpButton {
  background: #FFFFFF;
  color: #808080;
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 48px;
  width: 48px;
  font-size: 16px;
  z-index: 10;
  cursor: pointer;
  transition: background, color ease .5s;
  border-radius: 50%;
  border: none;
  box-shadow: 1px 1px 1px 1px #808080;
}
.ScrollUpButton:hover {
  color: #FFFFFF;
  background: #1E2536;
}
