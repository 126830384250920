.LessonPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  /* padding-left: 96px; */
  align-items: center;
  padding-bottom: 50px;
}
.LessonPage .wrapper .IconBack{
  color: #808080;
  margin: 28px 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.LessonPage .wrapper h1{
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 20px; 
}
.LessonPage .wrapper h2{
  margin-bottom: 15px; 
}
.LessonPage .wrapper .desciption {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  /* width: 928px; */
}
.LessonPage .wrapper {
  height: 100%;
  width: 928px;
  /* margin-right: auto; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

@media screen and (min-width: 2000px) {
  .LessonPage .wrapper {
    width: 1244px;
  }
}
@media screen and (max-width: 1360px) {
  .LessonPage .wrapper {
    width: 612px;
  }
}
@media screen and (max-width: 1124px) {
  .LessonPage {
    padding-left: 36px;
  }
  /* .LessonPage .wrapper {
    width: 296px;
  } */
  .LessonPage .wrapper h1{
    font-size: 22px;
    line-height: 28px;
  }
  .LessonPage .wrapper h2{
    font-size: 18px;
    line-height: 24px;
  }
  .LessonPage .wrapper .desciption {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 28px;
  }
}
@media screen and (max-width: 1124px) {
  .LessonPage .wrapper .IconBack{
    margin: 24px 0;
  }
}
@media screen and (max-width: 769px) {
  .LessonPage .wrapper {
    width: 296px;
  }
}
@media screen and (max-width: 426px) {
  .LessonPage {
    padding-left: 0px;
  }
  .LessonPage .wrapper {
    width: 100%;
    padding-bottom: 16px;
  }
  .LessonPage .wrapper h1{
    margin-left: 16px;
  }
  .LessonPage .wrapper h2{
    margin-left: 16px;
  }
  .LessonPage .wrapper .IconBack{
    margin: 24px 0 24px 20px;
    visibility: hidden;
  }
  .LessonPage .wrapper .desciption {
    padding: 0 16px;
  }
}