.AsideWindow {
  background: #FFFFFF;
  height: 100vh;
  max-width: 400px;
  box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  transition: all ease .4s;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  z-index: 10;
}

.AsideWindow::-webkit-scrollbar {
  width: 8px;
}

.AsideWindow::-webkit-scrollbar-track {
  background:#fff;
  /* border-radius: 4px; */
  /* opacity: 0.5; */
}
.AsideWindow::-webkit-scrollbar-thumb {
  background: #c7bcbc;
  opacity: 0.1;
  border-radius: 4px;
}
.AsideWindow::-webkit-scrollbar-thumb:hover {
  background: #fff;
  opacity: 1;
  border-radius: 4px;
}

.AsideWindow .Header {
  display: flex;
  justify-content: space-between;
  padding: 33px 33px 28px 20px;
}
.AsideWindow .Header h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
}
.AsideWindow .Header .close {
  color: #808080;
  display: flex;
  justify-content: center;
  align-items:center;
  align-self: start;
  height: 24px;
  width: 24px;
  cursor: pointer;
}
.AsideWindow .Header .close:hover {
  color:rgba(255, 255, 255, 0.5);
  background: #1D2B3D;
  border-radius: 4px;
}
.AsideWindow .Video {
  min-width: 400px;
  min-height: 300px;
}
.AsideWindow .TextBlock {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
}
.AsideWindow .TextBlock .TagsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.AsideWindow .TextBlock .Btntags {
  margin-bottom: 20px;
  padding: 10px 16px;
  cursor: pointer;
  border: none;
  /* outline: none; */
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.15), 0px 3px 16px 1.5px rgba(0, 0, 0, 0.08);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.AsideWindow .TextBlock .Btntags:hover {
  background: #f5f5f5;
}
.AsideWindow .TextBlock .TagsList span {
  color: #254AA5;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: 12px;
  cursor: pointer;
}
.AsideWindow .TextBlock p {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  white-space:pre-line;
  margin-bottom: 10px;
}

.AsideWindow .BtnSection {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 5px;
  cursor: pointer;
  background: #1E2536;
  border-radius: 8px;
  padding: 6px 20px;
  transition: background, color ease .5s;
  display: flex;
}
.AsideWindow .BtnSection:hover {
  color: #1E2536;
  background: #fff;
}

.AsideWindow h3 {
  margin-bottom: 16px;
}
.AsideWindow .BackButton {
  background: #FFFFFF;
  color: #808080;
  position: fixed;
  top: 20px;
  right: 390px;
  height: 48px;
  width: 48px;
  font-size: 16px;
  z-index: 10;
  cursor: pointer;
  transition: background, color ease .5s;
  border-radius: 50%;
  border: none;
  box-shadow: -1px -1px 1px 1px #808080;
  transform: rotate(-90deg);
  z-index: 11;
}
.AsideWindow .Button {
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 15px;
  cursor: pointer;
  background: #1E2536;
  border-radius: 4px;
  padding: 16px 20px;
  transition: background, color ease .5s;
}
.AsideWindow .BackButton:hover {
  color: #FFFFFF;
  background: #1E2536;
}
.AsideWindow .Button:hover {
  background: #FFFFFF;
  color: #1E2536;
}
.AsideWindow .EmptyThumbnail {
  min-height: 300px;
  min-width: 400px;
  color: #FFFFFF;
  background: #1E2536;
  display: flex;
  justify-content: center;
  align-items: center;
}