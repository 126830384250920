@import-normalize;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::before, ::after {
  box-sizing: inherit;
}

body {
  /* width: 100%;
  height: 100%; */
  overflow-x: hidden;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family:'Open Sans';
}

input[type=text] {
  font-family:'Open Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
input[type=text]::placeholder {
	font-size: 16px;
  font-family: 'Open Sans';
	color: #FFFFFF;
  opacity: 0.5;
}
input[type=text]::-webkit-input-placeholder {
	font-size: 16px;
  font-family: 'Open Sans';
  color: #FFFFFF;
  opacity: 0.5;
}
input[type=text]::-moz-placeholder {
	font-size: 16px;
  font-family: 'Open Sans';
	color: #FFFFFF;
  opacity: 0.5;
}
input[type=text]:-moz-placeholder {
	font-size: 16px;
  font-family: 'Open Sans';
	color: #FFFFFF;
  opacity: 0.5;
}
input[type=text]:-ms-input-placeholder {
	font-size: 16px;
  font-family: 'Open Sans';
	color: #FFFFFF;
  opacity: 0.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html,
body,
#root{
  height: auto;
  font-family: 'Open Sans', sans-serif;
} */