.g-button__input {
  display: flex;

  align-items: center;
  color: #4680c2;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding-right: 20px;
  cursor: pointer;
}

.g-button__input:hover {
  opacity: 0.8;
}

.g-button {
  margin-top: 20px;
  text-align: center;
}

.g-button__input-icon {
  margin-right: 10px;
  width: 60px;
}