.VideoPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 50px;
  /* padding-left: 96px; */
}
.VideoPage .wrapper {
  height: 100%;
  width: 100%;
  max-width: 928px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.VideoPage .wrapper .IconBack {
  color: #808080;
  margin: 28px 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.VideoPage .wrapper .Header {
  margin-bottom: 20px;
}
.VideoPage .wrapper .Header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.VideoPage .wrapper iframe {
  width: 100%;
  height: 525px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.VideoPage .wrapper .TextBlock .Btntags {
  margin-bottom: 20px;
  padding: 10px 16px;
  cursor: pointer;
  border: none;
  /* outline: none; */
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.15), 0px 3px 16px 1.5px rgba(0, 0, 0, 0.08);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.VideoPage .wrapper .TextBlock .Btntags:hover {
  background: #f5f5f5;
}
.VideoPage .wrapper .TextBlock .TagsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #254AA5;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.VideoPage .TextBlock {
  padding: 0 16px;
}
.VideoPage .wrapper .TextBlock .TagsList span{
  margin-right: 12px;
  cursor: pointer;
}
.VideoPage .wrapper .TextBlock .description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  white-space: pre-line;
  /* word-break: break-word; */
}

@media screen and (max-width: 1360px) {
  .VideoPage .wrapper {
    max-width: 612px;
  }
  .VideoPage .wrapper iframe {
    height: 370px;
  }
}
@media screen and (max-width: 1124px) {
  .VideoPage .wrapper,
  .VideoPage .desciption {
    /* max-width: 612px; */
  }
  .VideoPage .wrapper iframe {
    height: 370px;
  }
  .VideoPage {
    padding: 0;
  }
}
@media screen and (max-width: 850px) {
  .VideoPage {
    padding: 0 24px;
  }
}
@media screen and (max-width: 769px) {
  .VideoPage .wrapper .IconBack {
    visibility: hidden;
  }
  .VideoPage .wrapper iframe {
    height: 350px;
  }
}
@media screen and (max-width: 426px) {
  .VideoPage {
    padding: 0;
  }
  .VideoPage .wrapper {
    margin: 0;
    width: 100%;
    padding-bottom: 16px;
    max-width: none;
  }
  .VideoPage .wrapper .IconBack {
    margin: 24px 0 24px 20px;
    visibility: hidden;
  }
  .VideoPage .wrapper .Header {
    padding-left: 20px;
  }
  .VideoPage .wrapper .Header h1 {
    font-size: 22px;
    line-height: 28px;
  }
  .VideoPage .wrapper iframe {
    height: 300px;
    width: 100%;
    border-radius: 0px;
    margin-bottom: 8px;
  }
  .VideoPage .wrapper .TextBlock .TagsList {
    margin-right: 10px;
    margin-bottom: 16px;
  }
  .VideoPage .wrapper .TextBlock .description {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 376px) {
  .VideoPage .wrapper iframe {
    height: 250px;
  }
}
@media screen and (max-width: 321px) {
  .VideoPage .wrapper iframe {
    height: 210px;
  }
}