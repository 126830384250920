.SearchField {
  width: 264px;
  height: 48px;
  background: inherit;
  border-radius: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SearchField input{
  font-weight: 400;
  height: 16px;
  width: 100%;
  outline: 0;
  border: none;
  color:#FFFFFF;
  background: inherit;
}
.SearchField input::placeholder{
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}