.Collapsible .toggle {
  border: unset;
  cursor: pointer;
  background: #1D2B3D;
  color: #fff;
  opacity: 50%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 296px;
  height: 48px;
  text-align: start;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  overflow-x: hidden;
}
/* .Collapsible .toggle:hover {
  background: #fff;
  color: #1D2B3D;
} */

.Collapsible .content {
  color: #fff;
  /* height: 0px; */
  overflow: hidden;
  /* transition: all ease 0.5s; */
}