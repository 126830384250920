.CookieConsent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 12px;
  right: 12px;
  bottom: 12px;
  height: 88px;
  background: #1A1A1A;
  z-index: 1001;
  border-radius: 8px;
}
.CookieConsent .Text {
  margin-left: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #CCCCCC;
}
.CookieConsent .Text .Link {
  color: #FFFFFF;
  opacity: 0.5;
  text-decoration: none;
}
.CookieConsent .Button {
  width: 135px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid #254AA5;
  border-radius: 4px;
  padding: 12px 16px;
  margin-right: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #254AA5;
}
.CookieConsent .Button:hover {
  background: #EBF4FF;
}
@media screen and (max-width: 426px) {
  .CookieConsent {
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    height: 144px;
    border-radius: 0;
  }
  .CookieConsent .Text {
    margin-left: 0;
  }
  .CookieConsent .Text .Link {
    display: block;
    margin-bottom: 16px;
  }
  .CookieConsent .Button {
    width: 100%;
    margin-right: 0;
  }
}