.SkeletonLessonPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* padding-left: 96px; */
}
.SkeletonLessonPage .Wrapper {
  height: 100%;
  width: 928px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.SkeletonLessonPage .Wrapper .IconBack {
  color: #808080;
  margin: 28px 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.SkeletonLessonPage .Wrapper .Header {
  width: 320px;
  height: 32px;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  border-radius: 4px;
  margin-bottom: 28px;
}
.SkeletonLessonPage .Wrapper .Video {
  width: 100%;
  height: 525px;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  border-radius: 8px;
  margin-bottom: 20px;
}
.SkeletonLessonPage .Wrapper .TextBlock .TagsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 28px;
  column-gap: 10px;
  row-gap: 10px;
}
.SkeletonLessonPage .Wrapper .TextBlock .TagsList > div {
  width: 160px;
  height: 24px;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
.SkeletonLessonPage .Wrapper .TextBlock .Description > div {
  height: 18px;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  border-radius: 4px;
}
@media screen and (min-width: 2000px) {
  .SubsectionPage .wrapper {
    width: 1244px;
  }
}
@media screen and (max-width: 1360px) {
  .SkeletonLessonPage .Wrapper {
    width: 612px;
  }
  .SkeletonLessonPage .Wrapper .Video {
    height: 346px;
  }
}
@media screen and (max-width: 1124px) {
  .SkeletonLessonPage .Wrapper .Video {
    height: 300px;
  }
  /* .SkeletonLessonPage {
    padding-left: 36px;
  } */
}
@media screen and (max-width: 850px) {
  
}
@media screen and (max-width: 769px) {
  .SkeletonLessonPage .Wrapper {
    width: 298px;
  }
  .SkeletonLessonPage .Wrapper .Header {
    width: 100%;
  }
  .SkeletonLessonPage .Wrapper .IconBack{
    visibility: hidden;
  }
}
@media screen and (max-width: 426px) {
  .SkeletonLessonPage {
    padding: 0;
  }
  .SkeletonLessonPage .Wrapper {
    width: 100%;
  }
  .SkeletonLessonPage .Wrapper .Header {
    margin-left: 16px;
    margin-right: 16px;
    width: 80%;
  }
  .SkeletonLessonPage .Wrapper .Video {
    height: 250px;
  }
  .SkeletonLessonPage .Wrapper .TextBlock {
    margin: 0 16px 16px;
  }
}