.Footer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #1D2B3D;
}
.Footer .wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.2);;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255, 255, 255, 0.5);
  padding-top: 16px;
}
.Footer .wrapper p {
  margin-bottom: 8px;
  cursor: pointer;
}

.Footer .IconBlock {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  width: 196px;
  margin-top: 28px;
}

.Footer .IconBlock .Icon {
  fill:rgba(255, 255, 255, 0.4);
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 16px;
}
.Footer .IconBlock .Icon:last-child {
  height: 14px;
}

.Footer .IconBlock .Icon svg {
  /* color:rgba(255, 255, 255, 0.4); */
  fill-opacity: 0.4;
}