.AsideSubsectionWindow {
    background: #fff;
    height: 100vh;
    width: 400px;
    box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    transition: all ease .4s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    z-index: 10;
}

.AsideSubsectionWindow::-webkit-scrollbar {
    width: 8px;
}

.AsideSubsectionWindow::-webkit-scrollbar-track {
    background: #fff;
    /* border-radius: 4px; */
    /* opacity: 0.5; */
}

.AsideSubsectionWindow::-webkit-scrollbar-thumb {
    background: #c7bcbc;
    opacity: 0.1;
    border-radius: 4px;
}

.AsideSubsectionWindow::-webkit-scrollbar-thumb:hover {
    background: #fff;
    opacity: 1;
    border-radius: 4px;
}

.AsideSubsectionWindow .Header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}

.AsideSubsectionWindow .Header h4 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
}

.AsideSubsectionWindow h3 {
    margin-bottom: 16px;
}

.AsideSubsectionWindow .Header .close {
    color: #808080;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: start;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-left: 4px;
}

.AsideSubsectionWindow .Header .close:hover {
    color: rgba(255, 255, 255, 0.5);
    background: #1D2B3D;
    border-radius: 4px;
}

.AsideSubsectionWindow .Wrapper {
    display: flex;
    flex-direction: column;
    padding: 33px 12px 20px 20px;
    height: 100%;
    /* margin-right: auto; */
}

.AsideSubsectionWindow .Wrapper .Desciption {
    margin-bottom: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.AsideSubsectionWindow .Wrapper .Btn {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 15px;
    cursor: pointer;
    background: #1E2536;
    border-radius: 4px;
    padding: 16px 20px;
    transition: background, color ease .5s;
}

.AsideSubsectionWindow .Wrapper .BtnSection {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 5px;
    cursor: pointer;
    background: #1E2536;
    border-radius: 8px;
    padding: 6px 20px;
    transition: background, color ease .5s;
    display: flex;
}

.AsideSubsectionWindow .Btn:hover,
.AsideSubsectionWindow .Wrapper .BtnSection:hover {
    color: #1E2536;
    background: #fff;
}

.AsideSubsectionWindow .Wrapper .List {
    display: flex;
    flex-direction: column;
}

.AsideSubsectionWindow .Wrapper .List .Card {
    display: flex;
    width: 360px;
    height: 112px;
    margin-bottom: 12px;
}

.AsideSubsectionWindow .Wrapper .List .Card .Thumbnail {
    min-width: 168px;
    height: 112px;
    margin-right: 12px;
    cursor: pointer;
}

.AsideSubsectionWindow .Wrapper .List .Card .Thumbnail img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.AsideSubsectionWindow .Wrapper .List .Card .TextBlock {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.AsideSubsectionWindow .Wrapper .List .Card .TextBlock .Title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
}

.AsideSubsectionWindow .Wrapper .List .Card .TextBlock .Text {
    color: #808080;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: pre-wrap;
}

.AsideSubsectionWindow .BackButton {
    background: #FFFFFF;
    color: #808080;
    position: fixed;
    top: 2px;
    right: 355px;
    height: 38px;
    width: 28px;
    font-size: 16px;
    cursor: pointer;
    transition: background, color ease .5s;
    border-radius: 10%;
    border: none;
    transform: rotate(-90deg);
    z-index: 1111;
}

.AsideSubsectionWindow .BackButton:hover {
    color: #FFFFFF;
    background: #1E2536;
}

@media screen and (max-width: 426px) {
    .AsideSubsectionWindow {
        width: 100%;
    }

    .AsideSubsectionWindow .Wrapper {
        width: 100%;
    }

    .AsideSubsectionWindow .Wrapper .List .Card .TextBlock {
        width: 100%;
    }

    /* .AsideSubsectionWindow .Wrapper .List .Card .Thumbnail{
      width: 50%;
    } */
    .AsideSubsectionWindow .Wrapper .List .Card .Thumbnail {
        min-width: 40%;
    }
}