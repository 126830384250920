.Loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.Loader .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  min-width: 280px;
}

.Loader .wrapper img {
  display: inline-block;
  width: 88px;
  margin-bottom: 36px;
}

.Loader .loader { 
  width: 280px; 
  margin:0 auto;
  height: 4px;
  background: #EDEBEB;
  /* border-radius:10px;
  border:4px solid transparent; */
  position:relative;
  padding:1px;
}
.Loader .loader:before {
  content:'';
  /* border:1px solid rgb(161, 32, 32); 
  border-radius:10px; */
  position:absolute;
  top:-4px; 
  right:-4px; 
  bottom:-4px; 
  left:-4px;
}
.Loader .loader .loaderBar { 
  position:absolute;
  z-index: 1;
  height: 4px;
  /* border-radius:10px; */
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#1D2B3D; 
  width:0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}