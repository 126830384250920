.SkeletonSubsectionPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  /* padding-left: 96px; */
  align-items: center;
}
.SkeletonSubsectionPage .Wrapper {
  height: 100%;
  width: 928px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.SkeletonSubsectionPage .Wrapper .IconBack{
  color: #808080;
  margin: 28px 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.SkeletonSubsectionPage .Wrapper .Header {
  width: 320px;
  height: 32px;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  border-radius: 4px;
  margin-bottom: 28px;
}
.SkeletonSubsectionPage .Wrapper .Desciption {
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
}
.SkeletonSubsectionPage .Wrapper .Desciption > div {
  height: 18px;
  margin-bottom: 8px;
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  border-radius: 4px;
  width: 320px;
}
.SkeletonSubsectionPage .Wrapper .List{
  display: flex;
  flex-wrap: wrap;
  row-gap: 54px;
  column-gap: 20px;
}
.SkeletonSubsectionPage .Wrapper .List .Card{
  width: 296px;
  height: 248px;
}
.SkeletonSubsectionPage .Wrapper .List .Card > div {
  background: rgba(0, 0, 0, 0.08);
  opacity: 0.8;
  border-radius: 4px;
  height: 18px;
}
.SkeletonSubsectionPage .Wrapper .List .Card .Image {
  background: #808080;
  opacity: 0.1;
  border-radius: 8px;
  height: 166px;
  width: 100%;
  margin-bottom: 12px;
}
@media screen and (min-width: 2000px) {
  .SubsectionPage .wrapper {
    width: 1244px;
  }
}
@media screen and (max-width: 1360px) {
  .SkeletonSubsectionPage .Wrapper {
    width: 612px;
  }
}
@media screen and (max-width: 1124px) {
  /* .SkeletonSubsectionPage {
    padding-left: 36px;
  } */
  .SkeletonSubsectionPage .Wrapper {
    width: 612px;
  }
}
@media screen and (max-width: 769px) {
  .SkeletonSubsectionPage .Wrapper {
    width: 298px;
  }
  .SkeletonSubsectionPage .Wrapper .IconBack{
    visibility: hidden;
  }
}
@media screen and (max-width: 426px) {
  .SkeletonSubsectionPage .Wrapper {
    width: 100%;
  }
  .SkeletonSubsectionPage .Wrapper .Header {
    margin-left: 16px;
    max-width: 288px;
  }
  .SkeletonSubsectionPage .Wrapper .Desciption {
    width: 90%;
    margin-left: 16px;
    margin-right: 16px;
  }
  .SkeletonSubsectionPage .Wrapper .List {
    column-gap: 0;
    margin: 0 16px;
  }
  .SkeletonSubsectionPage .Wrapper .List .Card {
    width: 100%;
  }
}