.SelectField {
  min-width: 104px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-right: 12px;
  border: 1px solid #ccc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 3px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  outline: none;
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.SelectField.selected {
  background: #1E2536;
}
.SelectField.selected:hover {
  cursor: pointer;
  background: #2d364b;
}
.SelectField:hover {
  background: #F5F5F5;
  cursor: pointer;
}
.SelectField:focus {
  border-color: #1E2536;
}
.SelectField.selected .value {
  color: #FFFFFF;
}
.SelectField .value {
  flex-grow: 1;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1E2536;
}
.SelectField .close {
  cursor: pointer;
  background: inherit;
  color: #808080;
  width: 11px;
  height: 11px;
  margin-left: 10px;
}
.SelectField .caret {
  cursor: pointer;
  background: inherit;
  color: #808080;
  margin-left: 10px;
}
.SelectField .caret.show {
  transform: rotate(180deg);
}
.SelectField.selected .close:hover{
  color: #FFFFFF;
} 
.SelectField .caret:hover, 
.SelectField .caret:focus, 
.SelectField .close:hover, 
.SelectField .close:focus {
  color: #1E2536;
}
.SelectField .options {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  max-height: 15rem;
  overflow-y: auto;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.15), 0px 3px 16px 1.5px rgba(0, 0, 0, 0.08);
  border: none;
  min-width: 100%;
  width: max-content;
  left: 0;
  top: calc(100% + 6px);
  z-index: 4;
}
.SelectField .options.show {
  display: block;
}
.SelectField .options .option {
  text-align: start;
  min-height: 40px;
  cursor: pointer;
  padding: 10px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.SelectField .options .option:hover{
  background: #f5f5f5;
}
.SelectField .options .option.selected{
  background: #EBF4FF;
}