.VideoCard {
  width: 296px;
  max-height: 264px;
}
.VideoCard .Thumbnail{
  cursor: pointer;
  width: 296px;
  height: 195px;
  margin-bottom: 12px;
}
.VideoCard .Thumbnail img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.VideoCard .Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
}


.VideoCard .Description {
  color: #808080;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: pre-wrap;
  white-space: pre-line;
  /* word-break: break-all; */
}

@media screen and (max-width: 426px) {
  .VideoCard {
    width: 100%;
    max-height: 375px;
  }
  .VideoCard .Thumbnail{
    width: 100%;
    height: 275px;
  }
  .VideoCard .Thumbnail img {
    border-radius: 0;
  }
  .VideoCard .Title {
    padding: 0px 16px;
  }
  .VideoCard .Description {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 376px) {
  .VideoCard .Thumbnail{
    height: 250px;
  }
}
@media screen and (max-width: 321px) {
  .VideoCard .Thumbnail{
    height: 200px;
  }
}