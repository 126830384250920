.SkeletonSideNavBar {
  height: 100vh;
  width: 320px;
  background: #1E2536;
  padding: 0 12px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.SkeletonSideNavBar .Header {
  width: 296px;
  height: 80px;
  padding: 28px 140px 28px 16px;
}
.SkeletonSideNavBar .Header > div {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  height: 100%;
  width: 100%;
}
.SkeletonSideNavBar .Search {
  width: 296px;
  height: 60px;
}
.SkeletonSideNavBar .Item:nth-child(2n+1) {
  width: 296px;
  height: 48px;
  padding: 15px 40px 15px 16px;
}
.SkeletonSideNavBar .Item:nth-child(2n) {
  width: 296px;
  height: 48px;
  padding: 15px 16px;
}
.SkeletonSideNavBar .Item > div {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  height: 100%;
  width: 100%;
}