.bar-container {
  background: #fff;
  display: flex;
  width: 2rem;
  border-radius: 8px;
  row-gap: 0.1rem;
  flex-direction: column;
  align-items: center;
}

.bar-circular-progress {
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  /* background: conic-gradient(#e32012 3.6deg, #ededed 0deg); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar-circular-progress::before {
  content: "";
  position: absolute;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #fff;
}

.bar-progress-value {
  position: relative;
  font-size: 6px;
  font-weight: 500;
  color: #e32012;
}

.bar-text {
  display: flex;
  font-size: 7px;
  font-weight: 500;
  color: #fff;
  background-color: #e32012;
  padding: 0.05rem 0.4rem;
  border-radius: 20%;
}
