.ItemsList {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 41px;
  padding-bottom: 20px;
}
@media screen and (max-width: 426px) {
  .ItemsList {
    column-gap: 0px;
    row-gap: 24px;
  }
}