.Main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.Main .Tree {
  /* text-align: center;
  min-height: 100vh;
  width: 100%; */
}
