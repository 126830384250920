.CollapsibleVideo .toggle {
  border: unset;
  cursor: pointer;
  background: #1D2B3D;
  color: #fff;
  opacity: 50%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: 296px;
  min-height: 40px;
  text-align: start;
  display: flex;
  align-items: center;
  padding: 10px 24px 10px 40px;
  border-radius: 4px;
}

.CollapsibleVideo .toggle:hover {
  background: #fff;
  color: #1D2B3D;
}

.CollapsibleVideo .toggle span {
  /* margin-left: 10px; */
  /* margin: 10px 24px 10px 40px; */
  display: block;
}

.CollapsibleVideo .content {
  color: #fff;
  height: 0px;
  overflow: hidden;
  transition: all ease 0.5s;
}