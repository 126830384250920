.LessonCard {
  width: 296px;
  max-height: 264px;
}
.LessonCard .Thumbnail{
  cursor: pointer;
  width: 296px;
  height: 195px;
  margin-bottom: 12px;
  background: #1D2B3D;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1%;
  color: #fff;
}
.LessonCard .Thumbnail img {
  /* border-radius: 8px; */
  
  width: 30%;
  height: 30%;
  object-fit: cover;
}
.LessonCard .Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;
}


.LessonCard .Description {
  color: #808080;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: pre-wrap;
  white-space: pre-line;
  /* word-break: break-all; */
}

@media screen and (max-width: 426px) {
  .LessonCard {
    width: 100%;
    max-height: 375px;
  }
  .LessonCard .Thumbnail{
    width: 100%;
    height: 275px;
  }
  .LessonCard .Thumbnail img {
    border-radius: 0;
  }
  .LessonCard .Title {
    padding: 0px 16px;
  }
  .LessonCard .Description {
    padding: 0px 16px;
  }
}
@media screen and (max-width: 376px) {
  .LessonCard .Thumbnail{
    height: 250px;
  }
}
@media screen and (max-width: 321px) {
  .LessonCard .Thumbnail{
    height: 200px;
  }
}