.Tooltip,
.Tooltip.right {
  height: 100%;
  /* width: 100%; */
  position: relative;
}
.Tooltip::after,
.Tooltip::before {
  --scale: 0;
  position: absolute;
  z-index: 10;
  top: -0.2rem;
  left: 0;
  transform: translateX(0) translateY(-100%) scale(var(--scale));
  transition: 300ms transform;
  transform-origin: bottom center;
}
.Tooltip::before,
.Tooltip.right::before {
  content: attr(data-tooltip);
  color: #F5EFF7;
  background: #000000;
  opacity: 0.8;
  border-radius: 4px;
  padding: 6px 12px;
  max-width: max-content;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}
.Tooltip:hover:before,
.Tooltip.right:hover:before {
  --scale: 1;
}
.Tooltip.right::after,
.Tooltip.right::before {
  --scale: 0;
  position: absolute;
  z-index: 10;
  right: -0.4rem;
  top: 0;
  left: auto;
  transform: translateX(100%) translateY(25%) scale(var(--scale));
  transition: 300ms transform;
  transform-origin: left;
}