.ErrorPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorPage .wrapper {
  width: 320px;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items:center;
}

.ErrorPage .wrapper h2 {
  margin-top: 32px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
}

.ErrorPage .wrapper p {
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@media (max-width: 426px) {
  .ErrorPage .wrapper {
    transform: scale(0.7);
  }
}
